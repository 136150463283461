export interface VRideModel {
  id: number
  rideId: number
  eventId: number
  periodEventId?: number
  driverId: number
  driver: string
  vehicleId: number
  vehicle: string
  prevRideId?: number
  isEventValidated?: boolean
  flatCost?: number
  isForeignRide?: boolean
  doSendInvoice: boolean
  wasInvoiced: boolean
  isFreeOfCharge: number
  eventComments: string
  customer: string
  customerId: number
  customerPhone?: number
  customerPhoneInfo?: string
  departureAddressId?: number
  departureAddress: string
  departureStreet?: string
  departureZip?: string
  departureLocation?: string
  departureLat?: number
  departureLng?: number
  arrivalAddressId?: number
  arrivalAddress: string
  arrivalStreet?: string
  arrivalZip?: string
  arrivalLocation?: string
  arrivalLat?: number
  arrivalLng?: number
  additionalPassengers: number
  escorts: number
  prevRideWaitTime?: number
  departureAt?: string
  departureAtUnix?: number
  arrivalAt: string
  arrivalAtUnix?: number
  appointmentAt?: string
  appointmentAtUnix?: number
  distance: number
  duration: number
  periodId?: number
  periodDays: string[]
  periodStartAt: string
  periodFinishOn: string
  periodExcludedDates: string[]
  periodIncludedDates: string[]
  rideComments?: string
  rideInternalComment?: string
  createdById: number
}

export interface EventModel {
  id: number
  contactId: number
  periodEventId: number
  isForeignRide: boolean
  doSendInvoice: boolean
  comments: string
  isInvoiced: boolean
  handicaps: string[]
  flatCost: number
  isFreeOfCharge: boolean
  addEventWaitTime: number
  moneyPending: number
  reviewedById: number
  reviewedAt: string
  isValidated: boolean
  createdById: number
}

export interface HandicapModel {
  key: number
  value: string
}

export interface EventRevoke {
  eventId: number
  userId?: number
}

export const HANDICAPS: string[] = [
  'Rollstuhl',
  'Tixi-Rollstuhl',
  'Stöcke',
  'Rollator',
  'Sehbehinderung'
  // 'Tixi-Bon',
]
